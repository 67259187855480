@media(min-with: 768px){
    #slider-area{
        margin-top: 106px;
        background-color: red;
    }
}

#slider-area{
    margin-top: 0px;

    
}
.home-area-space{
    display: block;
    width: 100%;
    height: 729px;
    background-color: rgb(90, 141, 185);
}
